import * as React from "react"
import { Row } from "react-bootstrap"
import Cartier from "../components/cases/cartier"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"


const IndexPage = ({location}) => (

  <Layout>
    <div className="max-container-pages">
      <Seo title="Cartier" />
      <Row>
        <Cartier previous={location.state?.from ?? '/'} ></Cartier>
      </Row>
    </div>
  </Layout>
)

export default IndexPage
